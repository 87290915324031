import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { Button, Form, Modal, Select } from 'antd';
import { EditModuleEnum } from 'types/practiceFront';

interface IMedicationsPrescribedProps {
    field: EditModuleEnum;
    prescribableMedications?: string[];
    nonPrescribableMedications?: string[];
    onSubmit?: (data: {
        prescribableMedications: string[];
        nonPrescribableMedications: string[];
    }, field: EditModuleEnum, callback?: () => void) => void;
}

const MedicationsPrescribed: React.FC<IMedicationsPrescribedProps> = ({ field = EditModuleEnum.GENERIC_MEDICATIONS_PRESCRIBED, prescribableMedications, nonPrescribableMedications, onSubmit }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            prescribableMedications: prescribableMedications?.filter((item) => !!item) || [],
            nonPrescribableMedications: nonPrescribableMedications?.filter((item) => !!item) || [],
        });
    }, [form, prescribableMedications, nonPrescribableMedications]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            onSubmit?.({
                prescribableMedications: values.prescribableMedications.filter((item: string) => !!item),
                nonPrescribableMedications: values.nonPrescribableMedications.filter((item: string) => !!item),
            }, field, () => {
                setModalVisible(false);
            });
        });
    };
    return (
        <div className={s.wrap}>
            <Modal
                open={modalVisible}
                title="Add Medications prescribed"
                onCancel={() => setModalVisible(false)}
                okText="Save changes"
                onOk={handleSubmit}
                destroyOnClose
            >
                <div className={s.modal}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item label="Medications provider can prescribe" name="prescribableMedications">
                            <Select
                                mode="tags"
                                options={[]}
                            />
                        </Form.Item>
                        <Form.Item label="Medications provider can’t prescribe" name="nonPrescribableMedications">
                            <Select
                                mode="tags"
                                options={[]}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <div className={s.header}>
                <div className={s.title}>Medications prescribed</div>
                <Button onClick={() => setModalVisible(true)}>Add</Button>
            </div>
            {
                prescribableMedications?.length === 0 && nonPrescribableMedications?.length === 0 ? <div className={s.none}>Nothing here yet</div> : (
                    <div className={s.content}>
                        {
                            prescribableMedications?.length !== 0 && (
                                <div className={s.item}>
                                    <div className={s.itemTitle}>Medications provider may can prescribe</div>
                                    <div className={s.itemValue}>
                                        {prescribableMedications?.map((item) => (
                                            <div key={item} className={s.tag}>{item}</div>
                                        ))}
                                    </div>
                                </div>
                            )
                        }
                        {
                            nonPrescribableMedications?.length !== 0 && (
                                <div className={s.item}>
                                    <div className={s.itemTitle}>Medications provider can’t prescribe</div>
                                    <div className={s.itemValue}>
                                        {nonPrescribableMedications?.map((item) => (
                                            <div key={item} className={s.tag}>{item}</div>
                                        ))}
                                    </div>
                                </div>
                            )
                        }

                    </div>
                )
            }
        </div>
    );
};

export default MedicationsPrescribed;
